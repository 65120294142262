// components/Protected.jsx


import { useAuthenticator, Heading, Button, Table, TableHead, TableRow, TableCell, TableBody} from '@aws-amplify/ui-react';
import {React,  useState } from 'react';
import { Col, Row } from 'react-bootstrap';


import { DatePicker } from 'react-widgets/cjs';
import "../App.css";
import "react-widgets/scss/styles.scss";

export function Protected() {
  const { route } = useAuthenticator((context) => [context.route]);


  
  const [startDate, setStartDate] = useState(null);
  const [targetDate, setTargetDate] = useState(null);


  const DOB = () => {
    
    return (

      <DatePicker
        placeholder="mm/dd/yyyy"
        defaultValue={startDate}
        valueFormat={{ dateStyle: "medium" }}
        onChange={date => setStartDate(date)}
      />
    );
  };
  
  const TargetDate = () => {
    return (
  
     <DatePicker 
     placeholder="mm/dd/yyyy"
     defaultValue={targetDate}
     valueFormat={{ dateStyle: "medium" }}
     onChange={date => setTargetDate(date)} 
     />

    );
  };




  const PhysicalBio = (birthdate, currentdate) =>{
    let totalDays = currentdate-birthdate;
    let cycle =23;
    return Math.sin(totalDays/cycle*2*Math.PI);
  }

  const emotionalBio = (birthdate, currentdate) =>{
    let totalDays = currentdate-birthdate;
    let cycle =28;
    return Math.sin(totalDays/cycle*2*Math.PI);
  }

  const intellectualBio = (birthdate, currentdate) =>{
    let totalDays = currentdate-birthdate;
    let cycle =33;
    return Math.sin(totalDays/cycle*2*Math.PI);
  }
  
  const instictualBio = (birthdate, currentdate) =>{
    let totalDays = currentdate-birthdate;
    let cycle =38;
    return Math.sin(totalDays/cycle*Math.PI);
  }

  const getBioDesc = (bio)=>{
    return bio > 0.5 ? "High" : (bio < -0.5 ? "Low" :(bio>=-0.5 && bio < 0 ? "Medium Neg " : "Medium Pos" )); 
  }

  const getAverage= (phy, emo, inte, inst) =>{
    return (phy+emo+inte+inst)/4.0;
  }
  const Moon = {
    phases: ['new-moon', 'waxing-crescent-moon', 'quarter-moon', 'waxing-gibbous-moon', 'full-moon', 'waning-gibbous-moon', 'last-quarter-moon', 'waning-crescent-moon'],
    phase: function (year, month, day) {
      let c  = 0;
      let e = 0;
      let jd =0;
      let b = 0;
  
      if (month < 3) {
        year--;
        month += 12;
      }
  
      ++month;
      c = 365.25 * year;
      e = 30.6 * month;
      jd = c + e + day - 694039.09; // jd is total days elapsed
      jd /= 29.5305882; // divide by the moon cycle
      b = parseInt(jd); // int(jd) -> b, take integer part of jd
      jd -= b; // subtract integer part to leave fractional part of original jd
      b = Math.round(jd * 8); // scale fraction from 0-8 and round
  
      if (b >= 8) b = 0; // 0 and 8 are the same so turn 8 into 0
      return {phase: b, name: Moon.phases[b]};
    }
  };



  function CalculateBio(){
    console.log("CalculateBio");
   // alert(dobTestRef.current.value)
    //setStartDate(new Date(dobTestRef.current.value));

   
    const physicalCell     = document.getElementById("physical");
    const descPhysical     = document.getElementById("physicaldesc");
    const emotionalCell    = document.getElementById("emotional");
    const descEmotional    = document.getElementById("emotionaldesc");
    const intellectualCell = document.getElementById("intellectual");
    const descIntellectual = document.getElementById("intellectualdesc");
    const instictualCell   = document.getElementById("instictual");
    const descInstictual   = document.getElementById("instictualdesc");
    const average          = document.getElementById("average");
    const descAverage      = document.getElementById("averagedesc");
    const moonPhase        = document.getElementById("moonphase");

 
    if (startDate && targetDate){
        console.log("It reached here");
        let physical     = PhysicalBio(startDate, targetDate);
        let emotional    = emotionalBio(startDate, targetDate);
        let intellectual = intellectualBio(startDate, targetDate);
        let instictual   = instictualBio(startDate, targetDate);
        let avg          = getAverage(physical, emotional, intellectual, instictual);

        physicalCell.innerHTML      = parseInt(physical*100)+"%";
        descPhysical.innerHTML      = getBioDesc(physical);
        emotionalCell.innerHTML     = parseInt(emotional*100)+"%";
        descEmotional.innerHTML     = getBioDesc(emotional);
        intellectualCell.innerHTML  = parseInt(intellectual*100)+"%";
        descIntellectual.innerHTML  = getBioDesc(intellectual);
        instictualCell.innerHTML    = parseInt(instictual*100)+"%";
        descInstictual.innerHTML    = getBioDesc(instictual);
        average.innerHTML           = parseInt(avg*100)+"%";
        descAverage.innerHTML       = getBioDesc(avg);

        moonPhase.innerHTML         = Moon.phase(startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate()).name;

    }
  }

  function QuickCheck(){
    return (
        <>
        <Row>
          <Col lg={2}>
          </Col>
          <Col>
          <label> Select Birth Date</label> < DOB />  
          <label> Select Target Date</label> < TargetDate />
          <Button backgroundColor={'#fef7e5'} ariaLabel="" onClick={CalculateBio} isFullWidth={true} fontSize={'3rem'}>
            Calculate Biorythim
          </Button>
        <Table highlightOnHover={true} backgroundColor={'rgba(255,255,255,0.3)'} >
            <TableHead>
                <TableRow >
                    <TableCell   backgroundColor={'#FF7F7F'}>
                        Phy
                    </TableCell>
                    <TableCell backgroundColor={'#90EE90'}>
                        Emo
                    </TableCell>
                    <TableCell backgroundColor={'#CBC3E3'}>
                        Int
                    </TableCell>
                    <TableCell backgroundColor={'#ffffe0'}>
                        Inst
                    </TableCell>
                   
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell id = "physical" />
                    <TableCell id = "emotional" /> 
                    <TableCell id = "intellectual" />
                    <TableCell id = "instictual" />
                    
                </TableRow>
                <TableRow>
                    <TableCell id = "physicaldesc" />
                    <TableCell id = "emotionaldesc" /> 
                    <TableCell id = "intellectualdesc" />
                    <TableCell id = "instictualdesc" />
                   
                </TableRow>
                <TableRow>
                  <TableCell  backgroundColor={'#D3D3D3'} colSpan={2}>Average</TableCell>
                  <TableCell colSpan={2} >DOB Moon Phase</TableCell>
                </TableRow>
                <TableRow>
                <TableCell id = "average" colSpan={2}  />
                <TableCell id = "moonphase" colSpan={2} />
                </TableRow>
                <TableRow>
                <TableCell id = "averagedesc" colSpan={2} />
                <TableCell id = "moonphasedesc" colSpan={2} />
                </TableRow>
            </TableBody>
        </Table>
        </Col>
          <Col  lg={2}>
          </Col>
        </Row>
        

      </>
    )

  }



  const message =
    route === 'authenticated' ? <QuickCheck/> : 'Loading...';
  return ( <Heading level={1}>{message}</Heading>);
}
