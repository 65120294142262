
import {API, graphqlOperation } from 'aws-amplify';
import { listPatients } from '../graphql/queries';
import React , {  useState, useEffect, useRef} from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.css'; 

import { DatePicker } from 'react-widgets/cjs';
import "react-widgets/scss/styles.scss";
import "../App.css";
import { emotionalBio, instictualBio, intellectualBio, PhysicalBio, percentage} from './BioFunctions';
import { Modal, ModalBody, ModalHeader, Row, Col} from 'react-bootstrap';

import {
    SelectField, Button, TextField, Divider, Heading, Table, TableHead, TableCell, Text, TableRow, TableBody
  } from '@aws-amplify/ui-react';

import { useReactToPrint } from 'react-to-print';

  export function RetrieveBio(){


    
const [patients, setPatients] = useState([]);
const [useRefresh, setRefresh]= useState(false);

  useEffect(()=>{
    fetchPatients();
    setRefresh(false);
  },[useRefresh]);
  
  const fetchPatients= async ()=>{
    try {
      const patientData = await API.graphql(graphqlOperation(listPatients))
      const patientList = patientData.data.listPatients.items;
      console.log('patient list', patientList);
      setPatients(patientList);
    }
    catch(error){
      console.log('error on fetching patients', error);

    }
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const componentRef =useRef();
   const modalDocument= ()=>{

    //console.log("Testing dates",dates_range);
    return (
      <Modal show={show} onHide={handleClose} animation={false} fullscreen={true}> 
          <ModalHeader  closeButton>
            <Button onClick={handlePrint} > Print</Button>
          </ModalHeader>
         <ModalBody ref={componentRef}>
         <Row>
          <Col lg={2}>
          </Col>
          <Col>
          <Heading level={2}>Patient Biorythm</Heading>
          <Divider
           orientation="horizontal" />
          <TextField label={"Name"} value={patientName1} disabled={true} variation='quiet'/>
          <TextField label={"Date of Birth"} value={birthdate} disabled={true} variation='quiet'/>
          <Heading level={3}>Date Range for Biorythms</Heading>
          <TextField label={"Start Date"} value={start_date} disabled={true} variation='quiet'/>
          <TextField label={"End Date"} value={end_date} disabled={true} variation='quiet'/>
          <Divider
           orientation="horizontal" />

          <Table>
            <TableHead>
              <TableRow>
              <TableCell as ='th'>
                Date
              </TableCell>
              <TableCell as ='th'>
                Physical 
              </TableCell>
              <TableCell as='th'>
                Emotional
              </TableCell>
              <TableCell as='th'>
                Intellectual
              </TableCell>
              <TableCell as='th'>
                Instinctual
                </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
              {
                dates_range.map((dateItem) => {
                  return(
                  <TableRow key={dateItem}>
                    <TableCell >
                      {(new Date(dateItem)).toDateString()}
                    </TableCell>
                    <TableCell>
                      <Text>{percentage(PhysicalBio(birthdate, new Date(dateItem)))}</Text>
                    </TableCell>
                    <TableCell>
                    <Text>{percentage( emotionalBio(birthdate, new Date(dateItem)))}</Text>
                    </TableCell>
                    <TableCell>
                    <Text>{percentage( intellectualBio(birthdate, new Date(dateItem)))}</Text>
                    </TableCell>
                    <TableCell>
                    <Text>{percentage( instictualBio(birthdate, new Date(dateItem)))}</Text>
                    </TableCell>
                  </TableRow>
                )})
              }
                
            </TableBody>
          </Table>
          </Col>
          <Col lg={2}>
          </Col>
          </Row>
          
        </ModalBody>
        
      </Modal>
    );


   } 

  const [birthdate, setBirthDate] =useState( new Date());
  const [patientName1, setPatientName1]= useState("");
  const handleSelect = (index)=>{    

    let pName = patients[index].name;
    if (pName){
      setPatientName1( pName);
    }
   
    setBirthDate(new Date(patients[index].dob.replace(/-/g, '/')));
  }


  
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);

  let range_date =[];
  const [dates_range, setDatesRange] = useState([]);

  const calculateDateRange = ()=>{
  
    
    if (start_date && end_date){

      let dateCount = end_date.getTime() - start_date.getTime();
      dateCount = dateCount/(1000*3600*24);
      dateCount = parseInt(dateCount);
      console.log(dateCount);
      range_date.push(start_date);
      for (let index = 0; index < dateCount; index++) {
        var nextDay = new Date(range_date[index]);
        nextDay.setDate(nextDay.getDate()+1);
        range_date.push(new Date(nextDay));
      }
    
      console.log(range_date);
      setDatesRange(range_date);
      range_date=[];
      handleShow();
    }
   
  }
     
 

  const startDate = () => {
    
    return (

      <DatePicker
        placeholder="mm/dd/yyyy"
        defaultValue={start_date}
        valueFormat={{ dateStyle: "medium" }}
        onChange={date => setStartDate(date)}
      />
    );
  };
  
  const endDate = () => {
    return (
  
     <DatePicker 
     placeholder="mm/dd/yyyy"
     defaultValue={end_date}
     valueFormat={{ dateStyle: "medium" }}
     onChange={date => setEndDate(date)} 
     />

    );
  };

 
   const handlePrint = useReactToPrint({
      content: ()=> componentRef.current
    }
    );


  
    function RetrieveView(){
    
      return (
        <>
        <Row>
          <Col lg={2}>
          </Col>
          <Col>
      <SelectField
        backgroundColor={'#fef7e5'} 
        fontSize={'3rem'}
        label="Select A Patient"
        onChange={e=>handleSelect(e.target.value)}
        placeholder={patientName1 ? patientName1: "select a name"}
        >
        {
          patients.map((patient,idx)=>{
            return (
              <option key={idx} value={idx}>  {patient.name} </option>
            )

          })

        }
         
        </SelectField>


          <div>

            <h2>Select Range of Date</h2>
            
            {startDate()}
            {endDate() }
           <Button  backgroundColor={'#fef7e5'} onClick={calculateDateRange} isFullWidth={true} fontSize={'3rem'}> Generate Biorythms</Button>
              {modalDocument()}
          </div>
          </Col>
          <Col lg={2}>
          </Col>
          </Row>
        </>
      )
    }


    return <RetrieveView /> ;

  }

