import { TextField, Button, Alert } from "@aws-amplify/ui-react";
import { Col, Form, Row } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import './login.css';


export function Signin({onSignin}){
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
   
   //const [userRole , setUserRole ] = useState("");
    const navigate = useNavigate();
    const [isAlertVisible, setIsAlertVisible] = useState(false);

    const signIn = async () =>{
        try {
            const user = await Auth.signIn(username, password);
            onSignin();
            console.log(user)
            
            navigate('/')
            navigate(0)
          
        } catch (error) {
            console.log(
                "Error sigining in "
            )
            setIsAlertVisible(!isAlertVisible);
        }
    }

    return(
        <div className="login">
            <Row>
                <Col lg={2}>
                </Col>
                <Col>
                    <Form>
                        
                        <TextField
                            id="username"
                            label="Username"
                            value={username}
                            onChange={e=>setUserName(e.target.value)}
                        />
                        <TextField
                            id="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={e=>setPassword(e.target.value)}
                        />
                    
                    
                        <Button id="signinButton" onClick={signIn} isFullWidth={true} >
                                Sign In
                        </Button>
                        {isAlertVisible?(<Alert variation="error">Error with UserName or Password</Alert>):null}
                    </Form>
                </Col>
                <Col lg={2}>
                </Col>
              
            </Row>
        </div>

    );
}