import { Protected } from './components/Protected';
import { Login } from './components/Login';

import { Home } from './components/Home';
import { Layout } from './components/Layout';

import { RetrieveBio } from './components/RetrieveBio';
import { Dashboard } from './components/dashboard';
import  NavBarCustom from './components/NavBarCustom';
import { Routes, Route } from 'react-router-dom';
import { Signin } from './components/Signin';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';

export function MyRoutes() {

  const [loggedIn, setLoggedIn] = useState(false);

  const assesLoggedInState = ()=>{
      Auth.currentAuthenticatedUser()
      .then(sess=>{
        console.log('Logged In');
        setLoggedIn(true);
        
      })
      .catch(()=>{
        console.log("Not Logged In");
        setLoggedIn(false);
      })
  }
  useEffect(()=>{
    assesLoggedInState();
  }); 


    return (
        <>
        <NavBarCustom/>
        
        <Routes>
        
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
           {loggedIn ?<>

            <Route
              path="/protected"
              element={
                
                  <Protected />
                
              }
            />
            <Route
              path="/protected2"
              element={
                
                  <Dashboard />
               
              }
            />
            <Route
              path="/protected3"
              element={
          
                  <RetrieveBio />
             
              }
            />

           </>:""}
           
            <Route path="/login" element={<Login />} />
            <Route path='/signin' element={ <Signin onSignin={assesLoggedInState} />}/>
          </Route>
          
        </Routes>
        </>
    );
  }