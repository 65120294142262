// components/Layout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthenticator, View } from '@aws-amplify/ui-react';


export function Layout() {
  const { route } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);



  return (
    <>
      <View>
        {route === 'authenticated' ? '' : 'Please Login!'}
      </View>

      <Outlet />
    </>
  );
}
