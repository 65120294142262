// components/ProtectSecond.jsx

import {API, graphqlOperation } from 'aws-amplify';
import { listPatients } from '../graphql/queries';
import React , { useRef, useState, useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.css'; 

import {
   useAuthenticator,
    Heading, 
    Flex , 
    Button,
    TableFoot,
    Table, 
    TableBody, 
    TableCell,
    TableRow, 
    TableHead,
    Alert,
    Pagination,
    usePagination,
    TextField,
   
  } from '@aws-amplify/ui-react';


import "../App.css";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col} from 'react-bootstrap';

import { createPatient, deletePatient } from '../graphql/mutations';


//import { RetrieveBio } from './RetrieveBio';

export function Dashboard() {
  const { route } = useAuthenticator((context) => [context.route]);
/*   
function TabBars(){
  return (
    <Tabs
      spacing="equal"
      justifyContent="center"
      backgroundColor={'#fff'}
      borderColor = "transparent"
    >
      <TabItem title="Manage Patients" onClick={showManagePatient} />

      <TabItem title="Retrieve Biorhythm"  onClick={showRetrieveBio}/> 
    </Tabs>
  );
}



const [ManagePatientsActive, setManagePatientActive] = useState(true);
const showManagePatient = ()=>{
  setManagePatientActive(true);
}
const showRetrieveBio = ()=> setManagePatientActive(false);

function BioRetrieve(){
  return (
    <div>
      <RetrieveBio/>
    </div>
  )
}

function Selection(){
    return (
      <div >
          { ManagePatientsActive?   <ManagePatients/>: <BioRetrieve/>}
      </div>
    );
  }

*/
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const handleClick = async () => {
   
    setIsAlertVisible(true);
    handleClose();

    try{
       
       const patientDetails ={
        name :  inputRef.current['name'].value,
        dob:  inputRef.current['dob'].value 
       }
      console.log("patient", patientDetails);
      const newPatient = await API.graphql(graphqlOperation(createPatient,{input:patientDetails}));
      console.log("created new patient", newPatient)
      setRefresh(true);

    }catch(error){
      console.log("Error inserting", error);
    }
  
    
  };


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputRef = useRef({});
 


  const CreatePatientModel= ()=>(
    <Modal show={show} onHide={handleClose} >
      <ModalHeader closeButton>
        Create Patient
      </ModalHeader>
      <ModalBody>
        <TextField 
            placeholder='ex. Jonh Doe'
            label='Name'
            size="large"
            ref={name => inputRef.current['name']=name}
         />
         
        <TextField
          type={'date'}
          label='Date of Birth'
          ref={dob => inputRef.current['dob']=dob}
        />
        {isAlertVisible? (<Alert  variation={'success'}>Success</Alert> ):null}
      </ModalBody>
      <ModalFooter>
       
        <Button  onClick={handleClick} >
          insert
        </Button>
      </ModalFooter>
      </Modal>
  );


const [patients, setPatients] = useState([]);
const [useRefresh, setRefresh]= useState(false);

  useEffect(()=>{
    fetchPatients();
    setRefresh(false);
  },[useRefresh]);
  
  const fetchPatients= async ()=>{
    try {
      const patientData = await API.graphql(graphqlOperation(listPatients))
      const patientList = patientData.data.listPatients.items;
      console.log('patient list', patientList);
      setPatients(patientList);
    }
    catch(error){
      console.log('error on fetching patients', error);

    }
  }






const paginationProps = usePagination({ totalPages: 8 });

const deletePatientRecord = async (patientInput) =>{
  try {
    const patientID={
      id: patientInput.patient.id,
    };
    await API.graphql(graphqlOperation(deletePatient, {input: patientID}));
    setRefresh(true);

  }catch(e){
    console.log("error in deletePatient: ", e);
  }
}

function ManagePatients() {
 return (

    <>

      <Row>
          <Col lg={2}>
          </Col>
          <Col>

      <Button backgroundColor={'#fef7e5'} onClick={handleShow} isFullWidth={true} fontSize={'3rem'}> Add Patient </Button>
 
      <CreatePatientModel />
  
      <Table className="patientList" highlightOnHover={true}    >
              <TableHead>
                <TableRow>
                  <TableCell fontSize={'small'} as="th">
                    Patient Index
                  </TableCell>
                  <TableCell as="th" >
                    Name
                  </TableCell>
                  <TableCell as="th" >
                    Date of Birth
                  </TableCell>
                  <TableCell as="th" >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

              { 
                patients.map((patient,idx) =>{
                  return(
                    <TableRow  key={idx} >
                      <TableCell className="patientIndex">{idx}</TableCell> 
                      <TableCell >{patient.name} </TableCell> 
                      <TableCell className="patientdob">{new Date(patient.dob.replace(/-/g, '/')).toDateString()}</TableCell>
                      <TableCell> <Button backgroundColor={'#fef7e5'} onClick={()=>deletePatientRecord({patient})}>delete</Button></TableCell>
                      
                    </TableRow>  
                  )
                })
              }

              </TableBody>
         <TableFoot>
         <TableRow>
                  <TableCell as="th">
                    Patient Index
                  </TableCell>
                  <TableCell as="th" >
                    Name
                  </TableCell>
                  <TableCell as="th" >
                    Date of Birth
                  </TableCell>
                  <TableCell as="th" >
                    Actions
                  </TableCell>
                </TableRow>
              
         </TableFoot>
         
      </Table>
      <Pagination {...paginationProps}/>
      </Col>
      <Col  lg={2}>
      </Col>
      </Row>
    </>

 );

}
 


  function Dashboard(){
    return (
      <>

      <Flex direction={'column'}>
     
        <ManagePatients />
      </Flex>
    </>

    )

  }



  const message =
    route === 'authenticated' ? <Dashboard/> : 'Loading...';
  return <Heading level={1}>{message}</Heading>;
}
