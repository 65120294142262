
  export function emotionalBio (birthdate, currentdate) {
    let totalDays = currentdate-birthdate;
    let cycle =28;
    return Number( Math.sin(totalDays/cycle*2*Math.PI)).toFixed(2);
  }

  export function intellectualBio (birthdate, currentdate){
    let totalDays = currentdate-birthdate;
    let cycle =33;
    return Number( Math.sin(totalDays/cycle*2*Math.PI)).toFixed(2);
  }
  
  export function instictualBio (birthdate, currentdate){
    let totalDays = currentdate-birthdate;
    let cycle =38;
    return Number( Math.sin(totalDays/cycle*2*Math.PI)).toFixed(2);
  }

  export function PhysicalBio (birthdate, currentdate){
    console.log(birthdate, currentdate)
    let totalDays = currentdate-birthdate;
    let cycle =23;
    return Number( Math.sin(totalDays/cycle*2*Math.PI)).toFixed(2);
  }

  export function percentage(bio){
    return parseInt(bio*100)+"%";
  }