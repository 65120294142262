import React from 'react'




// components/Home.jsx
import { Heading } from '@aws-amplify/ui-react';
import { Row , Col} from 'react-bootstrap';


export function Home() {
  return (

    <>
    <Row>
      <Col lg={2}>
      <Row>
          <strong>Moon Phase of Today</strong>
          <div id="ex1" />
          {load_moon_phases(configMoon,moonToday)}
       </Row>

      </Col>
      <Col>
        <Heading level={3}>
           Welcome
        </Heading>
        <p><strong>Biophirom</strong> is a web platform that allows doctors to keep track of the Biorythm of patients</p>
        <p>Biorythm is a theory that originated in between 1897 and 1902 and gained it's popularity in the 1970s</p>
        <p>A Biorythm is a cycle pattern of alterations in physiology, emotions, intellect and others. It is said
           that one's life is affected by rhythmic biological cycles, one can forecast the ease to 
           perform certain tasks on specific dates and times.
        </p>
        
      </Col>
        <Col lg={2}>
      
          <Row>
          
          </Row>
        </Col>
    </Row>
    </>
  );
}


       
function load_moon_phases(obj,callback){
  var gets=[]
  for (var i in obj){
      gets.push(i + "=" +encodeURIComponent(obj[i]))
  }
  gets.push("LDZ=" + new Date(obj.year,obj.month-1,1) / 1000)
  var xmlhttp = new XMLHttpRequest()
  var url = "https://www.icalendar37.net/lunar/api/?" + gets.join("&")
  xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
          callback(JSON.parse(xmlhttp.responseText))
      }
  }
  xmlhttp.open("GET", url, true)
  xmlhttp.send()
}
function moonToday(moon){    
  var day = new Date().getDate()
  var dayWeek=moon.phase[day].dayWeek
  var html = "<div>" +
  "<b>" + moon.nameDay[dayWeek]+ "</b>" +
  "<div>" + day + " <b>" + moon.monthName + "</b> " +
  moon.year + "</div>" +
  "<div shadow>" + moon.phase[day].svg + "</div>" +
  "<div>" + moon.phase[day].phaseName + " " +
  "" + ((moon.phase[day].isPhaseLimit )? ""  :   Math.round(moon.phase[day].lighting) + "%") +
  "</div>" +
  "</div>"
  document.getElementById("ex1").innerHTML = html
}   
var configMoon = {
  lang  		:'en', 
  month 		:new Date().getMonth() + 1,
  year  		:new Date().getFullYear(),
  size		:150, 
  lightColor	:"rgb(255,255,210)", 
  shadeColor	:"black", 
  texturize	:false, 
}