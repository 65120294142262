import '../navbar.css'
import { useState } from 'react'
import { ReactComponent as Hamburger } from '../hamburger.svg'
import { ReactComponent as Brand } from '../logo.svg'

import { NavLink, useNavigate } from 'react-router-dom';

import { useAuthenticator} from '@aws-amplify/ui-react';

const NavBarCustom = () => {

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/signin');
  }


  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }


  return (
  <>
    
    <nav className='navbar'>
      <div className="container">
        <div className="logo">
          <Brand />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
        <ul>
        <li><NavLink to="/" onClick={() => navigate('/')} >Home</NavLink></li>
        {route !== 'authenticated' ? (
         <li> <NavLink to="/signin" onClick={() => navigate('/signin')} >Login</NavLink></li>
        ) : (
          <>
            <li><NavLink to="/protected" onClick={() => navigate('/protected')} >Quick Check</NavLink></li>
            <li><NavLink to="/protected2" onClick={() => navigate('/protected2')} >Patients</NavLink></li>
            <li><NavLink to="/protected3" onClick={() => navigate('/protected3')} >Biorhythm</NavLink></li>
            <li><NavLink to="/signin" onClick={() => logOut()} >Logout</NavLink></li>
          </>
          
        )}
        </ul>
        </div>
        </div>
      </nav>


    
    </>

  )
}

export default NavBarCustom