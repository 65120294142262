//App.jsx
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter} from 'react-router-dom';
import './App.css';
import { MyRoutes } from './MyRoutes';

function App() {
  return (
    <Authenticator.Provider >
         <BrowserRouter>
         <MyRoutes />
         </BrowserRouter>
     
    </Authenticator.Provider>
  );
}

export default App;



